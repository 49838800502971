import React from 'react';

class Home extends React.Component {
    render() {
        return (
            <div>
                <p>
                    Welcome to your music theory quizzes! Click on one of the
                    links to start studying
                </p>
            </div>
        );
    }
}

export default Home;
